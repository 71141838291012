<template>
    <div>
        <CCards
            icon="fa fa-filter"
            bIcon="fa fa-file"
            caption="Datos del Filtro"
            btn_name="ocultar"
            btn_name1="ocultar"
            :button="cp_descarga"
        >
            <CRow>
                <CCol sm="2">
                    <CInput  
                        label="Fecha Inicio"  
                        type="date" 
                        v-model="filtro.dateini"
                    />
                </CCol>
                <CCol sm="2">
                    <CInput 
                        label="Fecha Final"  
                        type="date" 
                        v-model="filtro.datefin" 
                        :min="filtro.dateini"
                    />
                </CCol>
                <CCol sm="3">
                    <cSelectForm
                        label="Seleccione tipo persona"
                        :options="data_tipopersona"
                        placeholder="Escoja tipo persona"
                        :value.sync="form_add_tipo.id"    
                        @change="load_seleccion"      
                    />
                </CCol>
                <CCol sm="3">
                    <cSelectForm
                        label="Seleccione"
                        :options="data_persona"
                        placeholder="Escoja"
                        :value.sync="form_add_persona.id"   
                        :disabled="vue.estado == 1 ? true : false"
                        @change="able_btn"
                    />
                </CCol>
                <CCol sm="2">
                    <button type="button" :disabled="vue.btnestado == 1 ? true : false" :class="btnClasses" style="margin-top:2em;width:100% !important" @click="loadTable"><i class="fas fa-search"></i>&nbsp; Filtrar</button>
                </CCol>
            </CRow>
        </CCards>
        
        <CRow>
            <CCol sm="12">
                <CTableWrapper
                    :items="data"
                    :fields="fields"
                    hover
                    striped
                    border
                    small
                    fixed
                    :caption="vue.texto"
                    icon="fa fa-file"
                    btn_name="ocultar"
                    :showhide_buttons="vue.showhide"
                    :size="'md'"
                    @action_ver="down_item_pdf"
                    @action_edit="mtd_edit_selection"
                />
            </CCol>
        </CRow>
        <!-- edit selection -->
    <CModalForm
      :size="'md'"
      :title="modal_edit_selection.title"
      :button="cp_button_edit_selection"
      :show.sync="modal_edit_selection.modal_form"
      @mtd_action="modal_action_edit_selection"
    >
      <CRow>
        <CCol sm="12">
          <select class="form-control" v-model="form_edit_select.selection">
            <option :value="null" disabled>[Seleccione un Monto por mes]</option>
            <option value="1">Monto por 12 meses</option>
            <option value="2">Monto por 24 meses</option>
            <option value="3">Monto por 36 meses</option>
          </select>
        </CCol>
      </CRow>
    </CModalForm>
    </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CCards from "../../components/shared/containers/Cards.vue";
import cSelectForm from '../../components/shared/inputs/cSelect.vue';
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from '../../main'

export default {
    components: { CTableWrapper, CCards, cSelectForm, CModalForm },
    data(){
        return{
            vue: {
                estado: 1,
                btnestado: 1,
                texto: 'Resultados del Filtro',
                showhide: [{
                    "btn_ver": 1, 
                    "btn_pass": 0, 
                    "btn_edit": 1, 
                    "btn_del": 0 
                }] // dato para los estados de los botones
            },
            fields: [
                "Id",
                "Proyecto",
                "Cliente",
                "Monto_Total",
                "Monto_Inicial",
                "Descuento"
            ],
            filtro: {
                dateini: '',
                datefin: ''
            },
            form_add_tipo: {
                id: ""
            },
            form_add_persona: {
                id: ""
            },
            data: [],
            data_tipopersona: [],
            data_persona: [],
             /** edit state lot */
            modal_edit_selection: {
                action: "",
                title: "",
                modal_form: false,
            },
            form_edit_select:{
                id:null,
                selection:null,
            },

        };
    },
    computed: {
        cp_descarga: function(){
            if (this.form_add_persona.id != "") return false;
            return true;
        },
        btnClasses () {
            return [`btn btn-${this.color || 'primary'}`]
        },
        cp_button_edit_selection: function(){
            if (this.form_edit_select.selection != null) return false;
            return true;
        },
    },
    created() {
        this.mtd_getdata();
    },
    methods: {
        ...mapActions(["get","post"]),
        mtd_getdata: function(){
            let array = [];
            array = [{"value":1,"label":"CLIENTE"},{"value":5,"label":"ASESOR"}];
            this.data_tipopersona = array;
            let actual = new Date();
            let fecha;
            fecha = actual.getFullYear() + '-'
                    + ('0' + (actual.getMonth()+1)).slice(-2) + '-'
                    + ('0' + actual.getDate()).slice(-2);
            this.filtro.dateini = fecha;
            this.filtro.datefin = fecha;
        },
        able_btn: function(){
            this.vue.btnestado = 0;
        },
        load_seleccion: function(){
            let id;
            id = this.form_add_tipo.id;

            if(id==1){
                this.get({
                    url: this.$store.getters.get__url + "/client",
                    token: this.$store.getters.get__token
                }).then((response) => {
                    this.vue.estado = 0;
                    this.data_persona = response.data_clients;
                }).catch((errors) => {});

            }else{
                this.get({
                    url: this.$store.getters.get__url + "/users/5",
                    token: this.$store.getters.get__token
                }).then((response) => {
                    this.vue.estado = 0;
                    this.data_persona = response.data_users;
                }).catch((errors) => {});
            }
        },
        loadTable: function(){
            let fecha1, fecha2, persona_id, tipo_persona;

            fecha1 = this.filtro.dateini;
            fecha2 = this.filtro.datefin;
            tipo_persona = this.form_add_tipo.id;
            persona_id = this.form_add_persona.id;

            if(tipo_persona==1){
                tipo_persona = "client_id";
            }else{
                tipo_persona = "user_id";
            }

            this.post({
                url: this.$store.getters.get__url + "/quotation",
                token: this.$store.getters.get__token,
                params: {
                    tipo_persona: tipo_persona,
                    id_persona: persona_id,
                    fecha_ini: fecha1,
                    fecha_fin: fecha2
                }
            }).then((response) => {
                this.data = response.data;
            }).catch((errors) => {});
        },
        down_item_pdf: function(id){
            this.post({
                url: this.$store.getters.get__url + "/reports/quotations",
                token: this.$store.getters.get__token,
                params:{
                    id: id,
                }
            }).then((response) => {
                window.open("/assets/cotizaciones/"+response+".pdf", '_blank');
            }).catch((errors) => {});
        },
        mtd_edit_selection:function(id){
            this.modal_edit_selection.modal_form = true;
            this.modal_edit_selection.action = 'edit';
            this.modal_edit_selection.title = "Editar estado de cotizacion: "+id;
            this.form_edit_select.id=id;

        },
        modal_action_edit_selection: function(){
      this.post({
        url: this.$store.getters.get__url + "/quotation/editselect",
        token: this.$store.getters.get__token,
        params: this.form_edit_select,
      })
        .then((response) => {
         
          this.modal_edit_selection = {
            action: "",
            title: "",
            modal_form: false,
          };
          bus.$emit("alert", {
            color: "success",
            message: "Monto por mes editado",
          });
          this.form_edit_select.selection =null;
          this.form_edit_select.id = null;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    }
}
</script>